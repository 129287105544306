import { Meta, Title } from '@solidjs/meta';
import { Heading, Container, Section, Page, Breadcrumbs, BreadcrumbItem } from '@troon/ui';
import { For, Show, Suspense } from 'solid-js';
import { createAsync, Navigate, useLocation } from '@solidjs/router';
import { ImageOverlay } from '../../../components/image-overlay';
import { useUser } from '../../../providers/user';
import { createContentfulRequest } from '../../api/content/[model]/_client';
import { BenefitCard } from '../../../components/benefit';
import type { RouteDefinition } from '@solidjs/router';

export default function AccessBenefits() {
	const user = useUser();
	const loc = useLocation();
	const benefits = createAsync(async () => getCards('access-member-benefits'), { deferStream: false });

	return (
		<Show when={user()?.activeTroonCardSubscription} fallback={<Navigate href={`/auth?redirect=${loc.pathname}`} />}>
			<Title>Troon Access Benefits | Troon Access | Troon</Title>
			<Meta
				name="description"
				content="The ultimate way to experience Troon golf. Exclusive access to savings at more than 150 participating Troon golf courses."
			/>

			<div class="relative mb-12 flex min-h-96 flex-col items-stretch bg-gradient-to-r from-neutral-950 to-brand-700 text-white sm:min-h-72 md:min-h-96">
				<Container class="z-20 -mb-20 mt-20 text-white">
					<Breadcrumbs appearance="current">
						<BreadcrumbItem href="/access">Troon Access</BreadcrumbItem>
						<BreadcrumbItem href="/access/benefits">Benefits</BreadcrumbItem>
					</Breadcrumbs>
				</Container>

				<div class="z-10 mx-auto my-16 flex h-full max-w-3xl grow flex-col items-center justify-center gap-4 px-4 pt-16 text-center text-white">
					<Heading as="h1">Troon Access Benefits</Heading>
					<p>Your Troon Access membership gets you access to incredible savings.</p>
				</div>
				<ImageOverlay class="relative top-1" />
			</div>

			<Page>
				<Container>
					<Section>
						<div class="mb-12 grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-8 lg:grid-cols-3">
							<Suspense>
								<For each={benefits()?.fields.benefits}>{(benefit) => <BenefitCard {...benefit!.fields} />}</For>
							</Suspense>
						</div>
					</Section>
				</Container>
			</Page>
		</Show>
	);
}

const getCards = createContentfulRequest('benefits');

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
